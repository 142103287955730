// 
// user.scss
// Use this to write your custom SCSS
//
.bg-gold {
    background-color: #ffb85f !important;
}
.btn-addcart {
    position: absolute;
    top: 3rem;
    right: .75rem;
    z-index: 5;
    padding: 0 !important;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.2s ease-in-out;
    border: 0;
    border-radius: 50%;
    background-color: #f3f5f9;
    color: #373f50;
    text-align: center;
    width: 2rem;
    height: 2rem;
}
.search-results {
    position: absolute;
    top: 50px;
    z-index: 1000;
    min-height: 100px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 0px 0px 10px 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 10px;
    width: 100%;
    padding: 10px;
}